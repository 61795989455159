export const DELIVERY_STATUS_0 = "DELIVERY_STATUS_RECEIPT";
export const DELIVERY_STATUS_1 = "DELIVERY_STATUS_PICKUP_SCHEDULED";
export const DELIVERY_STATUS_2 = "DELIVERY_STATUS_PICKUP_COMPLETED";
export const DELIVERY_STATUS_3 = "DELIVERY_STATUS_WAREHOUSED";
export const DELIVERY_STATUS_4 = "DELIVERY_STATUS_DELIVERY_STARTED";
export const DELIVERY_STATUS_5 = "DELIVERY_STATUS_DELIVERED";
export const DELIVERY_STATUS_6 = "DELIVERY_STATUS_DELIVERY_SENT_BACK";
export const DELIVERY_STATUS_7 = "DELIVERY_STATUS_DELIVERY_LOST";
export const DELIVERY_STATUS_8 = "DELIVERY_RIDER_ALLOCATED";
export const DELIVERY_STATUS_9 = "ADDRESS_NOT_SUPPORTED";
export const DELIVERY_STATUS_10 = "ACCIDENT";
export const DELIVERY_STATUS_11 = "CANCELED";

export const DELIVERY_TYPE_NORMAL = "1";
export const DELIVERY_TYPE_PICKUP = "2";
export const DELIVERY_TYPE_RETURN = "3";

export class Delivery {
  accidentAt = null;
  canceledAt = null;
  bookId = "";
  corpUser = null;
  deliveryCompletedDate = null;
  deliveryPlace = false;
  receiverName = null;
  releasedAt = null;
  rider = null;
  senderName = null;
  status = DELIVERY_STATUS_0;
  type = DELIVERY_TYPE_NORMAL;

  constructor(delivery) {
    this.bookId = delivery.bookId;

    switch (Number(delivery.status)) {
      case 0:
        this.status = DELIVERY_STATUS_0;
        break;
      case 1:
        this.status = DELIVERY_STATUS_1;
        break;
      case 2:
        this.status = DELIVERY_STATUS_2;
        break;
      case 3:
        this.status = DELIVERY_STATUS_3;
        break;
      case 4:
        this.status = DELIVERY_STATUS_4;
        break;
      case 5:
        this.status = DELIVERY_STATUS_5;
        break;
      case 6:
        this.status = DELIVERY_STATUS_6;
        break;
      case 7:
        this.status = DELIVERY_STATUS_7;
        break;
      case 8:
        this.status = DELIVERY_STATUS_8;
        break;
      case 10:
        this.status = DELIVERY_STATUS_10;
        break;
      case 11:
        this.status = DELIVERY_STATUS_11;
        break;
      default:
        throw new Error("잘못된 배송상태값입니다.");
    }

    this.accidentAt = delivery.accidentAt;
    this.canceledAt = delivery.canceledAt;
    this.corpUser = delivery.corpUser;
    this.deliveryCompletedDate = delivery.deliveryCompletedDate;
    this.deliveryPlace = delivery.deliveryPlace;
    this.receiverName = delivery.receiverName;
    this.releasedAt = delivery.releasedAt;
    this.rider = delivery.rider;
    this.senderName = delivery.senderName;
    this.type = delivery.type || DELIVERY_TYPE_NORMAL;
  }

  get displaySenderName() {
    return this.corpUser ? this.corpUser.title : this.senderName;
  }

  get errorMessage() {
    if (this.accidentAt || this.status === DELIVERY_STATUS_10) {
      return "해당 접수건은 사고 상태로 수거 물품 보관 장소를 등록할 수 없습니다. 문의사항이 있으신 경우 착 카톡상담채널로 문의 부탁드립니다.";
    } else if (this.canceledAt || this.status === DELIVERY_STATUS_11) {
      return "해당 접수건은 취소 상태로 수거 물품 보관 장소를 등록할 수 없습니다. 문의사항이 있으신 경우 착 카톡상담채널로 문의 부탁드립니다.";
    } else if (this.deliveryPlace) {
      return "해당 접수건은 이미 수거 물품 보관 장소가 등록되어 있어 재등록하실 수 없습니다. 착 카톡상담채널로 변경 문의 부탁드립니다.";
    } else if (this.type === DELIVERY_TYPE_NORMAL) {
      return "고객님의 배송접수건은 일반 접수건으로 반품/픽업 보관 장보 등록이 불가능합니다. 안내된 url로 접속하셔서 수령희망장소를 등록 부탁드립니다.";
    } else if (
      this.status === DELIVERY_STATUS_2 ||
      this.status === DELIVERY_STATUS_3 ||
      this.status === DELIVERY_STATUS_4 ||
      this.status === DELIVERY_STATUS_8
    ) {
      return "해당 접수건은 수거완료 상태로 수거 물품 보관 장소를 등록할 수 없습니다. 문의사항이 있으신 경우 착 카톡상담채널로 문의 부탁드립니다.";
    } else if (
      this.status === DELIVERY_STATUS_5 ||
      this.status === DELIVERY_STATUS_6 ||
      this.status === DELIVERY_STATUS_7
    ) {
      return "해당 접수건은 배송완료 상태로 수거 물품 보관 장소를 등록할 수 없습니다. 문의사항이 있으신 경우 착 카톡상담채널로 문의 부탁드립니다.";
    } else {
      return null;
    }
  }
}
