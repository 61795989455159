import React from "react";
import styled from "@emotion/styled";

const FooterWrap = styled.div`
  position: relative;
`;
const FooterContainer = styled.div`
  width: 100%;
  max-width: 799px;
  margin: 0 auto;
  padding: 24px 16px;
`;

const FooterInfo = styled.div`
  font-size: 16px;
  color: #999;
  margin-bottom: 32px;
`;
const FooterInfoHighlight = styled.div`
  color: #75ff6f;
`;
const FooterCopyright = styled.div`
  font-size: 12px;
  color: #999;
  border-top: 1px solid #333;
  padding-top: 8px;
`;

function Footer() {
  return (
    <FooterWrap>
      <FooterContainer>
        <FooterCopyright>
          착TM
          <br />
          Copyright © 2024 그루핑 All rights reserved.
        </FooterCopyright>
      </FooterContainer>
    </FooterWrap>
  );
}

export default Footer;
